@import "../styles/core.scss";

.model-list {
  .card {
    &:hover,
    &:active,
    &:focus {
      border-color: $gray-600 !important;
      .btn-open {
        background-color: rgba($color: white, $alpha: 0.8);
        display: block;
      }
    }
    &:active,
    &:focus {
      .btn-open {
        background-color: white;
      }
    }
  }

  .btn-open {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
